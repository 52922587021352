*, :before, :after {
  box-sizing: border-box;
}

ul, ol, fieldset {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], figure, blockquote, dl, dd, fieldset {
  margin: 0;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img {
  width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

button {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  word-wrap: break-word;
  font-kerning: normal;
  font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on;
  min-height: 100vh;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-size: .875rem;
  line-height: 1;
}

html {
  color: #fff;
  background-color: #060606;
}

#top-bar {
  height: 42px;
  position: fixed;
  top: 0;
}
/*# sourceMappingURL=index.6552e3e6.css.map */
