body {
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', sans-serif;
  font-feature-settings: 'kern' on, 'liga' on, 'rlig' on, 'calt' on;
}

html {
  background-color: #060606;
  color: #ffffff;
}

#top-bar {
  position: fixed;
  top: 0;
  height: 42px;
}
